import { graphql } from 'gatsby';
import { Trans } from "gatsby-plugin-react-i18next";
import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Layout from '../../components/nigeria/layout';
import SpecialBg from "../../images/nigeria/special-bg.png";

const IMSPolicy = () => {
    return (
        <Layout pageTitle="Integrated Management System Policy | Moniepoint MFB"
            description={''}>
            <Helmet>
                <link rel="alternate" href="https://moniepoint.com/ng/ims-policy" hreflang="x-default" />
                <link rel="alternate" href="https://moniepoint.com/ng/ims-policy" hreflang="en-ng" />
            </Helmet>
            <GetStartedContainer>
                <div className="bg-mask"></div>
                <div className="content">
                    <div className="get-started-section">
                        <h2 className={`title`}>
                            <Trans>Integrated Management System Policy Statement</Trans>
                        </h2>
                    </div>
                </div>
            </GetStartedContainer>
            <ContentContainer>
                <div className="content">
                    <div>
                        <div className="ims-list-outer">
                            <p className="ims-list-copy top">Moniepoint MFB. is committed to maintaining and improving information security and  business continuity processes by adopting an integrated management system. This provides a  framework for integration of the ISO 27001:2022, ISO 20000:2018 and ISO 22301:2019 standards.
                            </p>
                            <p className="ims-list-copy">The company defines our core values for Integrated Management System as follows:
                                <ul className="ims-list inner">
                                    <li>To ensure uninterrupted availability of all key business resources required to support  essential (or critical) business activities.</li>
                                    <li>To reduce the number of information security, Quality, Service management and business continuity high priority risks on Moniepoint MFB.'s risk register.</li>
                                    <li>To ensure that all persons employed by us are competent to carry out the specific work tasks by providing all necessary information, instruction, training and supervision.</li>
                                    <li>To provide for an orderly and expedited recovery/continuity of critical business  processes after a disruptive incident.</li>
                                    <li>To reduce or avoid information security breaches and related loss.  </li>
                                    <li>To Ensure Compliance with Moniepoint MFB. Contractual, Regulatory, and Legal requirements and reduce information security related regulatory sanctions/penalties. </li>
                                    <li>To ensure Information collected, held, and used by the organization is appropriately  protected and available in line with business requirements.</li>
                                    <li>To improve information security culture and consciousness in the organization.</li>
                                    <li>To provide training in information security, Service, Quality and Business continuity for key resources</li>
                                    <li>Create awareness to all staff on the needs and responsibilities of Service, Quality, Information Security &  Business Continuity Management System
                                    </li>
                                    <li>To ensure that the Confidentiality, Integrity and Availability of information is maintained  throughout business functions and processes </li>
                                    <li>To ensure information is only accessible to authorized persons from within or outside  the company and minimize damage by preventing and reducing the impact of security  incidents
                                    </li>
                                    <li>To optimize our business processes to always strive for zero defect and no waste attitude
                                    </li>
                                    <li>To ensure customer satisfaction
                                    </li>
                                    <li>To ensure that all employees are made aware of their individual obligations in respect of  Service Delivery.
                                    </li>
                                    <li>To ensure continual improvement of our Service, Quality, Information Security &  Business Continuity Management. System.</li>
                                </ul>
                            </p>
                            <p className="ims-list-copy">The Integrated Management System policy, objectives and targets will be reviewed annually (or  sooner if necessary) by Top Management. This policy statement is communicated to all  employees and persons working for or on behalf of the company and will be made available to  the public, stakeholders, and any other interested parties on request.
                            </p>
                        </div>
                    </div>
                </div>
            </ContentContainer>
        </Layout>
    )
}

export default IMSPolicy


export const query = graphql`
query {
    locales: allLocale(
      filter: { ns: { in: ["common", "nigeria"] }, language: { eq: "en" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const GetStartedContainer = styled.section`
  background: rgba(3, 87, 238, 1);
  background-size: fill;
  background-repeat: no-repeat;
  background-position: 50% 25%;
  color: white;
  position: relative;

  .bg-mask {
    background: url(${SpecialBg});
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: inherit;
    opacity: 0.4;
  }
  .content {
    max-width: 1440px;
    margin: auto;
    padding: 81px 151px 101px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 81px 60px 101px;
    }

    .get-started-section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-family: "Founders Grotesk";
        font-size: 58px;
        font-weight: 500;
        line-height: 66px;
        letter-spacing: 0em;
        text-align: center;
        max-width: 657px;
        margin-bottom: 8px;
      }

      .description {
        max-width: 623px;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
      }

      .get-started-button {
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(8px);
        margin: 0 auto;
        padding: 16px 24px;
        border: none;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .content {
      padding: 81px 30px 98px;
      .get-started-section {
        .title {
          font-size: 32px;
          line-height: 35.04px;
          margin-bottom: 16px;
        }

        .description {
          font-size: 16px;
          line-height: 26px;
          text-align: center;
        }
      }
    }
  }
`;

const ContentContainer = styled.section`
  .content {
    max-width: 1440px;
    margin: auto;
    padding: 81px 151px 101px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 81px 60px 101px;
    }



  .ims-list {
    padding-left: 0;

    &.inner {
      margin-top: 8px;
      padding-left: 30px;
      list-style-position: inside;
    }

    ul {
      list-style-type: disc;
    }

    &.sub {
      list-style-type: circle;
    }
  }

  .ims-list-outer {
    margin-bottom: 40px;
    list-style-position: inside;

    &::marker {
      font-size: 32px;
      font-weight: bold;
    }
  }

  .ims-list-header {
    font-family: "Founders Grotesk";
    font-style: normal;
    font-weight: bolder;
    font-size: 32px;
    line-height: 38px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 8px;
    display: inline-block;
    line-height: 1.5;

    @media only screen and (max-width: 768px) {
      font-size: 24px;
    }
  }

  .ims-list-copy {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    opacity: 0.8;
    margin-bottom: 12px;

    &.top {
      margin-top: 10px;
    }

    b {
        color: rgba(0, 0, 0, 0.9);
    }
  }

  .ims-list-sub {
    padding-left: 0;
    counter-reset: item;
  }

  .ims-list-header {
    font-family: "Founders Grotesk";
    font-weight: 500;
    font-size: 24px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 8px;
    display: inline-block;

    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }
  }

  li > b {
    color: rgba(0, 0, 0, 1);
  }

  .ims-list-outer-sub {
    margin-bottom: 16px;

    ul ul {
      li {
        list-style-type: circle;
      }
    }
  }

  ol > li {
    counter-increment: item;
  }

  ol.ims-list-sub > li {
    display: block;
  }

  ol.ims-list-sub > li:before {
    font-size: 24px;
    font-weight: 500;
    content: counters(item, ".") ". ";

    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }
  }

  .ims-table {
    margin: 16px auto;
  border: 1px solid;

  th, td {
    border: 1px solid;
    padding: 6px;
    font-size: 14px;
    }
  }


  .image-container {
    display: flex;
    gap: 16px;

    .ims-image {
      width: 100%;
      height: 100%;
      max-width: 512px;
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 48px 30px;
    }}
`;